// src/oidcConfig.ts
import { UserManagerSettings,WebStorageStateStore } from 'oidc-client-ts';

const oidcConfig: UserManagerSettings = {
  authority: 'https://uatapp.quickadopt.in/', //https://localhost:44301/
  client_id: 'react_dap',
  redirect_uri: 'https://uatweb.quickadopt.in/callback', //http://localhost:3000/callback
  post_logout_redirect_uri:'https://uatweb.quickadopt.in',   //http://localhost:3000
  response_type: 'code', 
  scope: 'openid profile api1', 
  automaticSilentRenew: true, 
  loadUserInfo: true,    
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

export default oidcConfig;
